@import "../../theme/index.module.scss";

.Container {
  display: block;
  padding: 1.45rem 0 0.25rem 0;
  position: relative;
}

.ContainerOpen {
  composes: Container;
  & > .Label {
    font-size: .875rem;
    top: .25rem;
  }
  & > .InputError:before {
    width: 100%;
  }
}

.borderBottom {
  border: none !important;
  border-bottom: 1px solid $black !important;
}

.Input {
  display: block;
  width: -webkit-fill-available;
  padding: .5rem;
  font-size: 1.125rem;
  background: transparent;
  color: $black;
  border: 1px solid $black;
  resize: none;
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.Label {
  font-size: 1.125rem;
  letter-spacing: 1px;
  display: block;
  position: absolute;
  color: $black;
  top: 2.125rem;
  left: 1rem;
  pointer-events: none;
  font-weight: $bold;
  transition: font-size .3s, top .3s;
}

.InputError {
  position: absolute;
  color: rgba(0, 0, 0, 0);
  font-size: 0.8em;
  pointer-events: none;
  width: 100%;
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: $white;
    position: absolute;
    bottom: 0;
    left: 0;
    visibility: visible;
    transition: width 0.3s;
  }
}

.filled {
  padding: 8px 0 0;

  input {
    padding: 0;
    border-radius: 4px;
    border: 1px solid #820000;
    background: #F7F7F7;
  }
}

.error {
  font-size: .75rem;
  text-indent: .75rem;
  text-align: left;
  padding: 0.3125em 0.5em;
  background: white;
  border-radius: 4px;
  color: red;
}

